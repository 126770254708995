import { da } from 'date-fns/locale';
import * as actionTypes from './AuthTypes';

export function login(data) {
  return {
    type: actionTypes.LOGIN,
    payload: data,
  };
}

export function clearLoginAction() {
  return {
    type: actionTypes.CLEAR_LOGIN,
    payload: {},
  };
}

export function updateRegistrationData(data) {
  // localStorage.setItem('registrationData', JSON.stringify(data));
  return {
    type: actionTypes.UPDATE_REGISTRATION_DATA,

    payload: data,
  };
}

export function updateAddnewRgistrationData(data) {
  return {
    type: actionTypes.UPDATE_NEW_STUDENT_REGISTRATION_DATA,

    payload: data,
  };
}

export function register(data) {
  return {
    type: actionTypes.REGISTER,
    payload: data,
  };
}
export function studentMyprofile() {
  return {
    type: actionTypes.STUDENT_PROFILE,
  };
}
export function studentChangePass(data, student) {
  return {
    type: actionTypes.STUDENT_CHANGE_PASS,
    payload: data,
    id: student,
  };
}
export function studentProfilePic(data) {
  return {
    type: actionTypes.STUDENT_PROFILE_IMG,
    payload: data,
  };
}
export function parentProfileAction(data) {
  return {
    type: actionTypes.PARENT_PROFILE,
    payload: data,
  };
}
export function parentChangePass(data) {
  return {
    type: actionTypes.PARENT_CHANGE_PASS,
    payload: data,
  };
}
export function parentProfilePic(data) {
  return {
    type: actionTypes.PARENT_PROFILE_IMG,
    payload: data,
  };
}
export function coCurricularActivitiesAction() {
  return {
    type: actionTypes.CO_CURRICULAR_ACTIVITIES,
  };
}
export function sendOtpAction(data) {
  return {
    type: actionTypes.SEND_OTP,
    payload: data,
  };
}

export function verifyOtpAction(data) {
  return {
    type: actionTypes.VERIFY_OTP,
    payload: data,
  };
}

export function getAllGradesAction(data) {
  return {
    type: actionTypes.GET_ALL_GRADES,
    payload: data,
  };
}
export function getUniqueSubjectsAction() {
  return {
    type: actionTypes.GET_UNIQUE_SUBJECTS,
  };
}

export function getSubjectsByclassAction(data) {
  return {
    type: actionTypes.GET_SUBJECTS_BY_CLASS,
    payload: data,
  };
}
export function getAllTimeslots() {
  return {
    type: actionTypes.GET_TIMESLOTS,
  };
}

export function getAllSubjects() {
  return {
    type: actionTypes.GET_SUBJECTS,
  };
}

export function getAllUniversities() {
  return {
    type: actionTypes.GET_UNIVERSITIES,
  };
}

export function getAllQualifications() {
  return {
    type: actionTypes.GET_QUALIFICATIONS,
  };
}

export function getAllCountries() {
  return {
    type: actionTypes.GET_COUNTRY_LIST,
  };
}

export function getAllStates(data) {
  return {
    type: actionTypes.GET_STATE_LIST,
    payload: data,
  };
}

export function getTimezoneList(data) {
  return {
    type: actionTypes.GET_TIMEZONE_LIST,
    payload: data,
  };
}

export function registerTeacher(data) {
  return {
    type: actionTypes.REGISTER_TEACHER,
    payload: data,
  };
}

export function deleteDocument(data) {
  return {
    type: actionTypes.DELETE_DOCUMENT,
    payload: data,
  };
}

export function verifyEmailTeacherAction(data) {
  return {
    type: actionTypes.CHCEK_EMAIL_TEACHER,
    payload: data,
  };
}
export function verifyEmailAction(data) {
  return {
    type: actionTypes.VERIFY_EMAIL,
    payload: data,
  };
}

export function sendOtpTeacherAction(data) {
  return {
    type: actionTypes.SEND_OTP_TEACHER,
    payload: data,
  };
}
export function sendOtpBookDemoAction(data) {
  return {
    type: actionTypes.SEND_OTP_BOOK_DEMO,
    payload: data,
  };
}

export function verifyOtpTeacherAction(data) {
  return {
    type: actionTypes.VERIFY_OTP_TEACHER,
    payload: data,
  };
}

export function verifyOtpBookDemoAction(data) {
  return {
    type: actionTypes.VERIFY_OTP_BOOK_DEMO,
    payload: data,
  };
}
export function clearOtpBookDemo() {
  return {
    type: actionTypes.UPDATE_VERIFY_OTP_BOOK_DEMO,
    payload: {},
  };
}

export function resetBookDemoAction(data) {
  return {
    type: actionTypes.RESET_OTP_BOOK_DEMO,
    payload: data,
  };
}

export function uploadTeacherDocumentUploadAction(data) {
  return {
    type: actionTypes.UPLOAD_TEACHER_DOCUMENT,
    payload: data,
  };
}
export function resetTeacherDocumentUploadAction() {
  return {
    type: actionTypes.TEACHER_RESET,
    payload: {},
  };
}

export function getCountryListAction(data) {
  return {
    type: actionTypes.UPDATE_GET_COUNTRY_LIST,
    payload: data,
  };
}

export function forgotPasswordTeacherAction(data) {
  return {
    type: actionTypes.FORGOT_PASSWORD_TEACHER,
    payload: { data },
  };
}

export function clearPasswordTeacherAction() {
  return {
    type: actionTypes.CLEAR_FORGOT_PASSWORD_DETAILS,
    payload: [],
  };
}

export function sendOtpParentAction(data) {
  return {
    type: actionTypes.SEND_OTP_PARENT,
    payload: data,
  };
}

export function verifyOtpParentAction(data) {
  return {
    type: actionTypes.VERIFY_OTP_PARENT,
    payload: data,
  };
}
export function registerParent(data) {
  return {
    type: actionTypes.REGISTER_PARENT,
    payload: data,
  };
}
export function addParentStudentAction(data) {
  return {
    type: actionTypes.ADD_STUDENT_PARENT,
    payload: data,
  };
}
export function updateParentStudentAction(data) {
  return {
    type: actionTypes.UPDATE_STUDENT_PARENT,
    payload: data,
  };
}
export function updateParentStuSubscriptionAction(data) {
  return {
    type: actionTypes.UPDATE_STUDENT_PARENT_SUBSCRIPTION,
    payload: data,
  };
}
export function updateParentStuSubscriptionPaymentAction(data) {
  return {
    type: actionTypes.UPDATE_PARENT_PAYMENT_SUBSCRIPTION,
    payload: data,
  };
}

export function updateParentLoginStudentnAction(data) {
  return {
    type: actionTypes.UPDATE_PARENT_LOGIN_STUDENT,
    payload: data,
  };
}
export function addguardianAction(data) {
  return {
    type: actionTypes.ADD_STUDENT_GUARDIAN,
    payload: data,
  };
}
export function addGuardianAction(data) {
  return {
    type: actionTypes.ADD_GUARDIAN,
    payload: data,
  };
}
export function createOrderPaypalAction(data) {
  return {
    type: actionTypes.CREATE_ORDER_PAYPAL,
    payload: data,
  };
}
export function captureOrderPaypalAction(data) {
  return {
    type: actionTypes.CREATE_ORDER_PAYPAL,
    payload: data,
  };
}
export function forgotPassword(data) {
  return {
    type: actionTypes.FORGOT_PASSWORD,
    payload: data,
  };
}

export const getUpdatedStudentData = (data) => ({
  type: actionTypes.GET_UPDATE_STUDENT_DATA,
  payload: data,
});

export const updateStudentData = (data) => ({
  type: actionTypes.UPDATE_STUDENT_DATA,
  payload: data,
});

export const getAllExamTypes = () => ({
  type: actionTypes.GET_ALL_EXAM_TYPE_DATA,
});

export const updateAllExamTypes = (data) => ({
  type: actionTypes.UPDATE_ALL_EXAM_TYPE_DATA,
  payload: data,
});

export const getAllMonthSlabs = () => ({
  type: actionTypes.GET_ALL_MONTHS_SLABS,
});

export const getCountries = () => {
  return {
    type: actionTypes.GET_COUNTRIES,
  };
};

export const getAllBoards = () => ({
  type: actionTypes.GET_ALL_BOARDS,
});

export const updateSubscribedSubjects = (data) => ({
  type: actionTypes.GET_NEW_UPDATED_SUBJECTS,
  payload: data,
});

export const getAllClassByCountry = (data) => ({
  type: actionTypes.GET_ALL_CLASS_BY_COUNTRY,
  payload: data,
});

export const getOpenPracticeCountryList = () => ({
  type: actionTypes.GET_OPEN_PRACTICE_COUNTRY_LIST,
});

export const getTotalPoints = () => ({
  type: actionTypes.GET_TOTAL_POINTS,
});

export const getGeoLocation = () => {
  return { type: actionTypes.GET_GEO_LOCATION };
};

// export const getCurricularPricing = () => ({
//   type: actionTypes.GET_ALL_MONTHS_SLABS,
// });
