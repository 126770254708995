 let envApi = process.env.REACT_APP_DEV_BASE_URL;
//let envApi = 'https://dev.tautmore.com/api';
//let envApi = 'http://localhost:3000/api';

// let envApi = process.env.REACT_APP_QA_BASE_URL;
// let envApi = process.env.REACT_APP_STAGING_BASE_URL;
// let envApi = process.env.REACT_APP_PROD_BASE_URL;

let Rkey = process.env.REACT_APP_RAZORPAY_TEST_KEY;
const adminImageUrl = process.env.REACT_APP_ADMIN_IMAGES_URL;
const decodeTeacherUrlKey = process.env.REACT_APP_DECODE_TEACHER_URL_KEY;
const blogApiUrl = process.env.REACT_APP_BLOG_URL
const awsAcess = process.env.REACT_APP_AWS_ACCESS_KEY;
const awsSecret = process.env.REACT_APP_AWS_SECRET_KEY;
let paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

// let paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD;

//  'https://bmhqezh50f.execute-api.us-east-1.amazonaws.com/development/api';
// 'https://60iyw91gsb.execute-api.us-east-1.amazonaws.com/staging/api';
//  'https://bnlqemg0ze.execute-api.us-east-1.amazonaws.com/qa/api';
//'https://u75lkusioi.execute-api.us-east-1.amazonaws.com/prod/api';

//'rzp_live_ri9Rlt1HelD7A4';
// 'rzp_test_rIkx18LMSPEdlb';

if (process.env.REACT_APP_STAGE === 'Build for dev') {
  envApi = process.env.REACT_APP_DEV_BASE_URL;
  // envApi = process.env.REACT_APP_PROD_BASE_URL;
 // paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD;
 // Rkey = process.env.REACT_APP_RAZORPAY_LIVE_KEY;
  //envApi = process.env.REACT_APP_PROD_BASE_URL;
} else if (process.env.REACT_APP_STAGE === 'Build for qa') {
  envApi = process.env.REACT_APP_QA_BASE_URL;
} else if (process.env.REACT_APP_STAGE === 'Build for staging') {
  envApi = process.env.REACT_APP_STAGING_BASE_URL;
} else if (process.env.REACT_APP_STAGE === 'Build for prod') {
  paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD;
  Rkey = process.env.REACT_APP_RAZORPAY_LIVE_KEY;
  envApi = process.env.REACT_APP_PROD_BASE_URL;
}

const config = {
  apiBaseUrl: envApi ,
  razorpayKey: Rkey,
  adminImageUrl: adminImageUrl,
  paypalClientId: paypalClientId,
  decodeTeacherUrlKey: decodeTeacherUrlKey,
  awsAcess: awsAcess,
  awsSecret: awsSecret,
  blogApiUrl: blogApiUrl,
};
export default config;
