import * as actionTypes from './AuthTypes';

const studentData = localStorage.getItem('taut-student');
const parentData = localStorage.getItem('taut-parent');
const registrationData = localStorage.getItem('registrationData');
const subscribedSubjects = localStorage.getItem('subscribedSubjects');
const subscribedOlympiadSubjects = localStorage.getItem(
  'subscribedOlympiadSubjects'
);
const subscribedCocurSubjects = localStorage.getItem('subscribedCocurSubjects');
const ipLocation = localStorage.getItem('ipLocation');

const initialState = {
  Login: JSON.parse(studentData) || {},
  LoginParent: JSON.parse(parentData) || {},
  isLoginLoading: false,
  register: {},
  studentprofile: {},
  studentchangePass: {},
  ParentProfile: [],
  parentChangePass: {},
  profileImage: {},
  registerrResponse: [],
  CoCurricularActivities: [],
  otp: [],
  isSendingOtp: false,
  verifyOtp: {},
  AllGrades: [],
  AllBoards: [],
  Subjects: [],
  SubjectsByclass: [],
  teacherData: [],
  timeSlots: [],
  universities: [],
  qualifications: [],
  countryList: null,
  statesList: [],
  timezoneList: [],
  subjects: [],
  docDelete: [],
  sendOtpTeacher: [],
  sendOtpBookDemo: [],
  verifyEmailTeacher: [],
  verifyEmailData: [],
  verifyOtpTeacher: [],
  verifyOtpBookDemo: [],
  isSendingOtpTeacher: [],
  forgotPasswordTeacher: [],
  forgotPassword: {},
  otpParent: [],
  verifyOtpParent: [],
  ParentRegisterData: [],
  parentStudentDate: [],
  addGuardian: [],
  createPaypalOrder: {},
  capturePaypalPayment: {},
  allExamTypes: {},
  registrationData: {},
  addnewRegistrationData: {},
  allMonthsSlabs: {},
  subscribedSubjects:
    JSON.parse(studentData)
      ?.data?.subjectsEnrolled?.filter(
        (itemmm) => new Date(itemmm?.expiryDate) >= new Date()
      )
      ?.map((sub) => sub?.subject?._id) || [],
  // syllabusHierarchy: [],
  classListByCountry: {},
  subscribedOlympiadSubjects:
    JSON.parse(studentData)
      ?.data?.olympiadSubscription?.filter(
        (itemmm) => new Date(itemmm?.expiryDate) >= new Date()
      )
      ?.map((sub) => sub?.subject?._id) || [],
  subscribedCocurSubjects:
    JSON.parse(studentData)
      ?.data?.cocurricularsEnrolled?.filter(
        (itemmm) => new Date(itemmm?.expiryDate) >= new Date()
      )
      ?.map((sub) => sub?.cocurricularActivity?._id) || [],
  teacherRegData: {},
  braingymPointsErned: 0,
  openPracticeCountryList: {},
  geoLocationData: JSON.parse(ipLocation) || null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_REGISTRATION_DATA:
      return {
        ...state,
        registrationData: action.payload,
      };
    case actionTypes.UPDATE_NEW_STUDENT_REGISTRATION_DATA:
      return {
        ...state,
        addnewRegistrationData: action.payload,
      };
    case actionTypes.UPDATE_LOGIN:
      let res = action.payload?.data?.subjectsEnrolled ?? [];
      res = res
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.subject?._id);
      localStorage.setItem('subscribedSubjects', JSON.stringify(res));

      let olympRes = action.payload?.data?.olympiadSubscription ?? [];
      olympRes = olympRes
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.subject?._id);
      localStorage.setItem(
        'subscribedOlympiadSubjects',
        JSON.stringify(olympRes)
      );

      let cocurRes = action.payload?.data?.cocurricularsEnrolled ?? [];
      cocurRes = cocurRes
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.cocurricularActivity?._id);
      localStorage.setItem('subscribedCocurSubjects', JSON.stringify(cocurRes));
      return {
        ...state,
        Login: action.payload,
        subscribedSubjects: res,
        subscribedOlympiadSubjects: olympRes,
        subscribedCocurSubjects: cocurRes,
      };
    case actionTypes.UPDATE_LOGIN_PARENT:
      return {
        ...state,
        LoginParent: action.payload,
      };
    case actionTypes.CLEAR_LOGIN:
      return {
        ...state,
        Login: {},
        LoginParent: {},
      };

    case actionTypes.SET_LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: action.payload,
      };
    case actionTypes.UPDATE_REGISTER:
      return {
        ...state,
        register: action.payload,
        registerrResponse: action,
      };
    case actionTypes.UPDATE_STUDENT_PROFILE:
      return {
        ...state,
        studentprofile: action.payload,
      };
    case actionTypes.UPDATE_STUDENT_CHANGE_PASS:
      return {
        ...state,
        studentchangePass: action.payload,
      };
    case actionTypes.UPDATE_STUDENT_PROFILE_IMG:
      return {
        ...state,
        profileImage: action.payload,
      };
    case actionTypes.UPDATE_PARENT_PROFILE:
      return {
        ...state,
        ParentProfile: action.payload,
      };
    case actionTypes.UPDATE_PARENT_CHANGE_PASS:
      return {
        ...state,
        parentChangePass: action.payload,
      };
    case actionTypes.UPDATE_PARENT_PROFILE_IMG:
      return {
        ...state,
        profileImage: action.payload,
      };
    case actionTypes.UPDATE_CO_CURRICULAR_ACTIVITIES:
      return {
        ...state,
        CoCurricularActivities: action.payload,
      };
    case actionTypes.IS_SENDING_OTP:
      return {
        ...state,
        isSendingOtp: action.payload,
      };

    case actionTypes.IS_SENDING_OTP_TEACHER:
      return {
        ...state,
        isSendingOtpTeacher: action.payload,
      };
    case actionTypes.UPDATE_SEND_OTP:
      return {
        ...state,
        otp: action.payload,
        verifyOtp: {},
      };

    case actionTypes.UPDATE_VERIFY_OTP:
      return {
        ...state,
        verifyOtp: action.payload,
      };

    case actionTypes.UPDATE_VERIFY_OTP_TEACHER:
      return {
        ...state,
        verifyOtpTeacher: action.payload,
      };
    case actionTypes.UPDATE_VERIFY_OTP_BOOK_DEMO:
      return {
        ...state,
        verifyOtpBookDemo: action.payload,
      };
    case actionTypes.UPDATE_RESET_OTP_BOOK_DEMO:
      return {
        ...state,
        verifyOtpBookDemo: [],
      };
    case actionTypes.TEACHER_DOCUMENT:
      return {
        ...state,
        teacherRegData: action.payload,
      };
    case actionTypes.TEACHER_RESET_DOCUMENT:
      return {
        ...state,
        teacherRegData: action.payload,
      };

    case actionTypes.UPDATE_GET_ALL_GRADES:
      return {
        ...state,
        AllGrades: action.payload,
      };
    case actionTypes.UPDATE_GET_UNIQUE_SUBJECTS:
      return {
        ...state,
        Subjects: action.payload,
      };
    case actionTypes.UPDATE_GET_SUBJECTS_BY_CLASS:
      return {
        ...state,
        SubjectsByclass: action.payload,
      };
    case actionTypes.UPDATE_REGISTER_TEACHER:
      return {
        ...state,
        teacherData: action.payload,
      };

    case actionTypes.UPDATE_GET_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
      };
    case actionTypes.UPDATE_GET_UIVERSITIES:
      return {
        ...state,
        universities: action.payload,
      };
    case actionTypes.UPDATE_GET_QUALIFICATIONS:
      return {
        ...state,
        qualifications: action.payload,
      };

    case actionTypes.UPDATE_GET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };

    case actionTypes.UPDATE_GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };

    case actionTypes.UPDATE_GET_STATE_LIST:
      return {
        ...state,
        statesList: action.payload,
      };

    case actionTypes.UPDATE_GET_TIMEZONE_LIST:
      return {
        ...state,
        timezoneList: action.payload,
      };

    case actionTypes.UPDATE_DELETE_DOCUMENT:
      return {
        ...state,
        docDelete: action.payload,
      };

    case actionTypes.UPDATE_SEND_OTP_TEACHER:
      return {
        ...state,
        sendOtpTeacher: action.payload,
      };
    case actionTypes.UPDATE_SEND_OTP_BOOK_DEMO:
      return {
        ...state,
        sendOtpBookDemo: action.payload,
      };
    case actionTypes.UPDATE_CHCEK_EMAIL_TEACHER:
      return {
        ...state,
        verifyEmailTeacher: action.payload,
      };

    case actionTypes.UPDATE_VERIFY_EMAIL_RESPOSE:
      return {
        ...state,
        verifyEmailData: action.payload,
      };

    case actionTypes.UPDATE_FORGOT_PASSWORD_TEACHER:
      return {
        ...state,
        forgotPasswordTeacher: action.payload,
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    case actionTypes.CLEAR_FORGOT_PASSWORD_DETAILS:
      return {
        ...state,
        forgotPasswordTeacher: action.payload,
      };
    case actionTypes.UPDATE_SEND_OTP_PARENT:
      return {
        ...state,
        otpParent: action.payload,
        verifyOtp: {},
      };

    case actionTypes.UPDATE_VERIFY_OTP_PARENT:
      return {
        ...state,
        verifyOtpParent: action.payload,
      };
    case actionTypes.UPDATE_REGISTER_PARENT:
      return {
        ...state,
        ParentRegisterData: action.payload,
      };
    case actionTypes.UPDATE_ADD_GUARDIAN:
      return {
        ...state,
        addGuardian: action.payload,
      };
    case actionTypes.ADD_STUDENT_PARENT:
      return {
        ...state,
        parentStudentDate: action.payload,
      };
    case actionTypes.UPDATE_STUDENT_PARENT: {
      const index = state.parentStudentDate?.findIndex(
        (todo) => todo.id === action.payload?.id
      );
      const newArray = [...state.parentStudentDate]; // making a new array
      newArray[index].status = 'registered'; // changing value in the new array
      newArray[index].id = action.payload?._id;
      newArray[index].sData = action.payload?.sData;
      return {
        ...state,
        parentStudentDate: newArray,
      };
    }
    case actionTypes.UPDATE_STUDENT_PARENT_SUBSCRIPTION: {
      const index = state?.parentStudentDate?.findIndex(
        (todo) => todo.id === action.payload?.id
      );
      const newArray = [...state.parentStudentDate]; // making a new array
      newArray[index].subscription = action.payload?.sub;
      return {
        ...state,
        parentStudentDate: newArray,
      };
    }
    case actionTypes.UPDATE_PARENT_PAYMENT_SUBSCRIPTION: {
      const index = state.parentStudentDate?.findIndex(
        (todo) => todo._id === action.payload?.id
      );
      const newArray = [...state.parentStudentDate]; // making a new array
      newArray[index].subscription = action.payload?.sub;
      return {
        ...state,
        parentStudentDate: newArray,
      };
    }
    case actionTypes.UPDATE_PARENT_LOGIN_STUDENT:
      let parentRes = action.payload?.data?.subjectsEnrolled ?? [];
      parentRes = parentRes
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.subject?._id);
      localStorage.setItem('subscribedSubjects', JSON.stringify(parentRes));

      let parentolympRes = action.payload?.data?.olympiadSubscription ?? [];
      parentolympRes = parentolympRes
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.subject?._id);
      localStorage.setItem(
        'subscribedOlympiadSubjects',
        JSON.stringify(parentolympRes)
      );

      let parentcocurRes = action.payload?.data?.cocurricularsEnrolled ?? [];
      parentcocurRes = parentcocurRes
        ?.filter((itemmm) => new Date(itemmm?.expiryDate) >= new Date())
        ?.map((sub) => sub?.cocurricularActivity?._id);
      localStorage.setItem(
        'subscribedCocurSubjects',
        JSON.stringify(parentcocurRes)
      );
      return {
        ...state,
        Login: action.payload,
        subscribedSubjects: parentRes,
        subscribedOlympiadSubjects: parentolympRes,
        subscribedCocurSubjects: parentcocurRes,
      };
    case actionTypes.UPDATE_CREATE_ORDER_PAYPAL:
      return {
        ...state,
        createPaypalOrder: action.payload,
      };
    case actionTypes.UPDATE_CAPTURE_PAYPAL_PAYMENT: {
      return {
        ...state,
        capturePaypalPayment: action.payload,
      };
    }
    case actionTypes.GET_UPDATE_STUDENT_DATA: {
      return { ...state };
    }
    case actionTypes.UPDATE_STUDENT_DATA: {
      let newState = { ...state };
      if (action.payload.statusCode === 200) {
        newState.Login.data.subjectsEnrolled =
          action.payload.data.subjectsEnrolled;
        newState.Login.data.cocurricularsEnrolled =
          action.payload.data.cocurricularsEnrolled;
        newState.Login.data.olympiadSubscription =
          action.payload.data.olympiadSubscription;

        const index = newState.LoginParent.data.students.findIndex(
          (student) => student.id === action.payload.data.id
        );
        newState.LoginParent.data.students[index].subjectsEnrolled =
          action.payload.data.subjectsEnrolled;
        newState.LoginParent.data.students[index].cocurricularsEnrolled =
          action.payload.data.cocurricularsEnrolled;
        newState.LoginParent.data.students[index].olympiadSubscription =
          action.payload.data.olympiadSubscription;
        localStorage.setItem('taut-student', JSON.stringify(newState.Login));
        localStorage.setItem(
          'taut-parent',
          JSON.stringify(newState.LoginParent)
        );
      }
      return { ...newState };
    }

    case actionTypes.UPDATE_ALL_EXAM_TYPE_DATA: {
      return {
        ...state,
        syllabusHierarchy: action.payload,
        allExamTypes: action.payload,
      };
    }
    case actionTypes.UPDATE_ALL_MONTHS_SLABS: {
      return {
        ...state,
        allMonthsSlabs: action.payload,
      };
    }
    case actionTypes.UPDATE_ALL_BOARDS: {
      return {
        ...state,
        AllBoards: action.payload,
      };
    }
    case actionTypes.UPDATE_ALL_CLASS_BY_COUNTRY: {
      return {
        ...state,
        classListByCountry: action.payload,
      };
    }
    case actionTypes.UPDATE_TOTAL_POINTS: {
      return {
        ...state,
        braingymPointsErned: action.payload,
      };
    }
    case actionTypes.UPDATE_OPEN_PRACTICE_COUNTRY_LIST: {
      return {
        ...state,
        openPracticeCountryList: action.payload,
      };
    }
    case actionTypes.UPDATE_GEO_LOCATION: {
      return {
        ...state,
        geoLocationData: action.payload,
      };
    }

    default:
      return { ...state };
  }
}
